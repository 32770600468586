import { graphql, useStaticQuery } from "gatsby";
import React from "react";

import Company from "../components/Company";
import Layout from "../components/Layout";
import SEO from "../components/Seo";

const CompanyPage = () => {
  const data = useStaticQuery(graphql`
    query CompanyQuery {
      allContentfulCompany {
        edges {
          node {
            body {
              childMarkdownRemark {
                html
              }
            }
            images {
              fluid(maxWidth: 1400, quality: 100) {
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
              }
            }
            imageFooter {
              fluid(maxWidth: 1400, quality: 100) {
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
              }
            }
            metaDescription
            metaTitle
          }
        }
      }
    }
  `);
  const dataNode = data.allContentfulCompany.edges[0].node;

  return (
    <Layout>
      <SEO metaDescription={dataNode.metaDescription} title={dataNode.metaTitle} />
      <Company body={dataNode.body.childMarkdownRemark.html} images={dataNode.images} imageFooter={dataNode.imageFooter} />
    </Layout>
  );
};
export default CompanyPage;
