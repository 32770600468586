import Img from "gatsby-image";
import React from "react";
import Divider from "../Divider";
import styles from "./index.module.css";

const Company = props => (
  <div className={styles.el}>
    <div className={styles.container}>
      <Divider />
      <div className={styles.images}>
        {props.images.map((image, i) => (
          <Img loading="eager" fluid={image.fluid} />
        ))}
      </div>

      <div className={styles.body}>
        <div className={styles.title}>Company Profile</div>
        <div
          dangerouslySetInnerHTML={{
            __html: props.body,
          }}
        />
      </div>
      <Divider />
      <div className={styles.imageFooter}>
        <Img loading="eager" fluid={props.imageFooter.fluid} />
      </div>
    </div>
  </div>
);

export default Company;
